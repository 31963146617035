import classnames from 'classnames'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import {readEndpoint} from 'redux-json-api'

import {Button} from '../../../../dashboard/src/components/blocks/Buttons'
import {corpMaxDashboardUrl} from '../../../../lib/urlTools'
import Forms from '../../../../shared_components/forms'
import {goToInterstitials} from '../../../../lib/interstitialTools'
import {TextGutterMedium} from '../../../../dashboard/src/components/blocks/Texts'
import Container from '../../../../lib/Container'
import GlobalStyleOverrides from '../../../../shared_components/core/style-overrides/GlobalStyleOverrides'
import Logger from '../../../../lib/NewLogger'
import SecureImage from '../../assets/images/secure.png'
import SessionApi from '../../../../auth/src/web/SessionApi'
import SessionStore from '../../../../auth/src/stores/SessionStore'
import storePrototype from '../../../../shared_components/StorePrototype'
import storeWrapper from '../../../../shared_components/storeWrapper'
import SubscriptionActions from '../../actions/SubscriptionActions'

import './couponOnlyActivation.scss'

Container.registerStore('coupon', storePrototype(SubscriptionActions.Types.DID_APPLY_COUPON))
Container.registerStore('subscription', storePrototype(SubscriptionActions.Types.DID_CREATE_SUBSCRIPTION))

export class CouponOnlyActivation extends React.Component {
  constructor() {
    super()

    this.state = {errors: [], processing: false, sendUserToInterstitials: false}
    this.store = Container.getStore('coupon')
    this.subscriptionStore = Container.getStore('subscription')

    this.onSubscribed = this.onSubscribed.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.applyCoupon = this.applyCoupon.bind(this)
    this.createSubscription = this.createSubscription.bind(this)
    this.goToNextPage = this.goToNextPage.bind(this)
  }

  componentDidMount() {
    this.store.addChangeListener(this.onUpdate)
    this.subscriptionStore.addChangeListener(this.onSubscribed)
    SessionStore.addChangeListener(this.goToNextPage)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.coupon.errors.length > 0)
      this.logCouponEvent('user_submitted_coupon_unsuccessfully')
    else if (nextProps.coupon.data.coupon && nextProps.coupon.data.coupon.valid)
      this.logCouponEvent('user_submitted_coupon_successfully')
  }

  onUpdate() {
    const storeState = this.store.getState()

    if (storeState.errors.length)
      this.setCouponError()
    else
      this.createSubscription(storeState.data.coupon)
  }

  goToNextPage() {
    if (this.state.sendUserToInterstitials)
      this.props.nextPage()
  }

  onSubscribed() {
    this.props.readEndpoint('user-configs').then(() => {
      const {currentUser} = this.props
      const errors = this.subscriptionStore.getState().errors

      if (errors.length) {
        this.setState({errors, processing: false})
      } else if (currentUser && currentUser.has_interstitials) {
        // Need to fetch current user here so that no network call is called unnecessarily when switching from `pending-requests` to `dashboard` - Atanda
        this.setState({sendUserToInterstitials: true}, SessionApi.fetchCurrentUser)
      } else {
        this.props.nextPage(corpMaxDashboardUrl)
      }
    })
  }

  componentWillUnmount() {
    this.store.removeChangeListener(this.onUpdate)
    this.subscriptionStore.removeChangeListener(this.onSubscribed)
    SessionStore.removeChangeListener(this.goToNextPage)
  }

  applyCoupon() {
    const coupon = (this.refs.form.formData().couponCode || '').trim()

    this.setCouponError(coupon)

    if (coupon)
      SubscriptionActions.applyCoupon({partner: {coupon}})
  }

  createSubscription(coupon) {
    if (coupon.percent_off === 100) {
      this.setState({processing: true})
      SubscriptionActions.createSubscription({subscriptions: {coupon: coupon.id}})
    } else {
      this.setCouponError()
    }
  }

  logCouponEvent(eventName) {
    Logger.log({
      name: eventName,
      payload: {partner_id: this.props.partner.id}
    })
  }

  setCouponError(coupon) {
    this.setState({errors: coupon ? [] : 'Please enter a valid code.', processing: !!coupon})
  }

  render() {
    return (
      <GlobalStyleOverrides>
        <div className={classnames('coupon-only-activation', this.props.className)}>
          <h1>Start your subscription</h1>
          <TextGutterMedium>
            {this.props.partner.user_messaging || 'Redeem your Everplans Premium subscription by putting your activation code below.' }
          </TextGutterMedium>
          <Forms.Form className='core' ref='form'>
            <Forms.Input
              label='Enter your activation code'
              name='couponCode'
              ref='couponCode'
              tabIndex={4}
            />
          </Forms.Form>
          <Forms.Errors errors={this.state.errors} />
          <Button onClick={this.applyCoupon} processing={this.state.processing}>Start subscription</Button>
          <div className='secure-payment'>
            <img className='payment-image' src={SecureImage} alt='Secure Payment, Everplans' />
            <p className='secure-section-text'>
              All payments are handled through a secure payment processing company and all credit card details are stored on their secured
              servers. Everplans does not store your credit card details.
            </p>
          </div>
        </div>
      </GlobalStyleOverrides>
    )
  }
}

CouponOnlyActivation.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    has_interstitials: PropTypes.bool
  }),
  coupon: PropTypes.shape({
    data: PropTypes.shape({
      coupon: PropTypes.shape({
        valid: PropTypes.bool
      })
    }),
    errors: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ])
  }),
  nextPage: PropTypes.func,
  partner: PropTypes.shape({
    id: PropTypes.integer,
    user_messaging: PropTypes.string
  }),
  readEndpoint: PropTypes.func
}

CouponOnlyActivation.defaultProps = {
  nextPage: goToInterstitials
}

export default storeWrapper(
  connect(null, {readEndpoint})(CouponOnlyActivation),
  [{actionType: [SubscriptionActions.Types.DID_APPLY_COUPON], name: 'coupon'}]
)
