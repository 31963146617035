import {BoxGutterMedium} from '../../../dashboard/src/components/blocks/Boxes'
import {Button} from '../../../dashboard/src/components/blocks/Buttons'
import CollapsibleSection from './CollapsibleSection'
import DropdownTooltip from '../../../shared_components/core/tooltips/DropdownTooltip'
import Lorem from '../lib/Lorem'
import {TextGutterMedium} from '../../../dashboard/src/components/blocks/Texts'
import Tooltip from '../../../shared_components/core/tooltips/Tooltip'
import RecurringTooltip from '../../../shared_components/core/tooltips/RecurringTooltip'
import HoverTooltip from '../../../shared_components/core/tooltips/HoverTooltip'


const TooltipSection = () => (
  <CollapsibleSection heading='Tooltip'>
    <BoxGutterMedium>
      <aside>
        <TextGutterMedium>
          <h3>Recurring Tooltip</h3>
          <RecurringTooltip launcher={<Button>Show Tooltip</Button>}>
            <header>
              <h4>Recurring Tooltip heading</h4>
            </header>
            <p>Text to briefly describe the tooltip.</p>
            <Button>Got It</Button>
          </RecurringTooltip>
        </TextGutterMedium>
      </aside>

      <aside>
        <TextGutterMedium className='dropdown-tooltip-section'>
          <h3>Dropdown Tooltip</h3>
          <DropdownTooltip launcher={<Button>Show Dropdown Tooltip</Button>} id='dropdown-tooltip-example-1'>
            <ul>
              <li>Edit What's Shared</li>
              <li>Edit Unlocking Permission</li>
            </ul>
          </DropdownTooltip>
        </TextGutterMedium>
      </aside>

      <aside>
        <TextGutterMedium>
          <h3>Hovering Tooltip</h3>
          <HoverTooltip launcher={<Button>Show Hover Tooltip</Button>}>
            <p>This displays on hover</p>
          </HoverTooltip>
        </TextGutterMedium>
      </aside>

      <aside>
        <h3>Tooltip</h3>
        <TextGutterMedium>
          A tooltip is used to provide additional information about something when the user either moves the mouse pointer or mouse click on an element.
        </TextGutterMedium>
        <TextGutterMedium>
          <Lorem paragraphCount={1} />
          <Tooltip id='tooltip-example-1'>
            <header>
              <h4>Tooltip heading</h4>
            </header>
            <p>Text to briefly describe the tooltip.</p>
            <Button>Got It</Button>
          </Tooltip>
        </TextGutterMedium>
      </aside>
    </BoxGutterMedium>
  </CollapsibleSection>
)

export default TooltipSection
