import PropTypes from 'prop-types'
import {useState, useRef, useEffect} from 'react'

import {Cancel, Continue} from '../../../../shared_components/ButtonTypes'
import {ControlButton} from '../../../../shared_components/ButtonTypes'
import Forms from '../../../../shared_components/forms'
import Modal from '../../../../shared_components/Modal'
import modalWrapper from '../../../../shared_components/ModalWrapper'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'

import FirmActions from '../../actions/FirmActions'
import headshotPlaceholder from '../../../assets/images/headshot-placeholder.png'
import storeWrapper from '../../../../shared_components/storeWrapper'
import {usePrevious} from '../../../../lib/hooks'

const EditContactCardModal = (props, context) => {
  const formRef = useRef()
  const [processing, setProcessing] = useState(false)
  const prevContactCardUpdates = usePrevious(props.contactCardUpdates)
  const {isDifferent} = useNotificationContext()

  useEffect(() => {
    isDifferent(props.contactCardUpdates.data, prevContactCardUpdates.data).shortSuccess('Your changes have been saved.')
    isDifferent(props.contactCardUpdates.errors, prevContactCardUpdates.errors).shortError("We're sorry, but something went wrong while trying to update the contact card. Please try again.")
  }, [props.contactCardUpdates])

  const firmDomains = () => {
    if (props.currentUser.account_manager) {
      return (
        props.firm.data.organization_id &&
        props.organizations.data.find(organization => organization.id === props.firm.data.organization_id).domain_names
      )
    } else {
      return props.organization.data.domain_names
    }
  }

  /**
   * Validates email against domain if firm has an organization
   * @param {String} name
   * @param {Object} data
   */
  const emailValidator = (name, data) => Forms.Validators.emailDomainValidator(name, data, firmDomains())

  const updateContactCard = () => {
    setProcessing(true)
    const form = formRef.current
    // const errors =

    if (form.validate().length === 0) {
      const formData = form.formData()

      // Don't send back head shot if it is unchanged:
      if (formData.image_url === props.contactCard.head_shot)
        delete formData.image_url // nevermind the linting issue here until we stop supporting IE 'cause IE doesn't support Reflect.deleteProperty - Atanda

      FirmActions.updateContactCard({contact_card: formData}, props.params)
      context.closeModal()
    }

    setProcessing(false)
  }

  const phoneValidator = (name, data) => {
    var constraints = {}
    constraints[name] = {
      length: {
        maximum: 25,
        tooLong: '^Phone number is too long.'
      }
    }
    return Forms.Validators.template(name, data, constraints)
  }


  const contactCard = props.contactCard

  return (
    <div className='edit-contact-card'>
      <ControlButton buttonText='Edit' faClass='fa-pencil-square-o' onClick={context.showModal} />
      <Modal closer={context.closeModal} visible={props.showModal}>
        <h2>Edit Contact Card</h2>
        <div className='form-wrapper'>
          <Forms.Form onSubmit={updateContactCard} ref={formRef}>
            <Forms.Input autoFocus defaultValue={contactCard.name} label='Name:' name='name' tabIndex='1' validator={Forms.Validators.required} />
            <Forms.Input defaultValue={contactCard.accreditation} label='Accreditation:' name='accreditation' tabIndex='2' />
            <Forms.Input defaultValue={contactCard.title} label='Title:' name='title' tabIndex='3' />
            <Forms.Input defaultValue={contactCard.firm_name} label='Firm:' name='firm_name' tabIndex='4' validator={Forms.Validators.required} />
            <Forms.Input defaultValue={contactCard.email} label='Email Address:' name='email' validator={emailValidator} tabIndex='5' />
            <Forms.Input defaultValue={contactCard.phone} label='Telephone:' name='phone' tabIndex='6' validator={phoneValidator} />
            <Forms.Input defaultValue={contactCard.url} label='URL:' name='url' tabIndex='7' validator={Forms.Validators.urlValidator} />
            <Forms.ImageUpload defaultValue={contactCard.head_shot} name='image_url' placeholderImage={headshotPlaceholder} validator={null} />
          </Forms.Form>
          <div className='button-group'>
            <Cancel onClick={context.closeModal} tabIndex='9' />
            <Continue loading={processing} onClick={updateContactCard} tabIndex='8'>Save</Continue>
          </div>
        </div>
      </Modal>
    </div>
  )
}

EditContactCardModal.defaultProps = {
  contactCardUpdates: {
    data: {},
    errors: [],
    message: null
  }
}

EditContactCardModal.propTypes = {
  contactCard: PropTypes.shape({
    accreditation: PropTypes.string,
    email: PropTypes.string,
    firm_name: PropTypes.string,
    head_shot: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  contactCardUpdates: PropTypes.shape({
    data: PropTypes.object,
    errors: PropTypes.array
  }),
  currentUser: PropTypes.shape({
    account_manager: PropTypes.bool
  }),
  firm: PropTypes.shape({
    data: PropTypes.object
  }),
  organization: PropTypes.shape({
    data: PropTypes.object
  }),
  organizations: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object)
  }),
  params: PropTypes.shape({
    firmId: PropTypes.string,
    firmUserSeatId: PropTypes.string
  }).isRequired,
  showModal: PropTypes.bool.isRequired
}

EditContactCardModal.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

export default storeWrapper(
  modalWrapper(EditContactCardModal),
  [{actionTypes: [FirmActions.Types.DID_UPDATE_CONTACT_CARD], name: 'contactCardUpdates'}]
)
