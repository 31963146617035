import classnames from 'classnames'
import {fromJS} from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import * as Buttons from '../../dashboard/src/components/blocks/Buttons'
import Error from './Error'
import Fields from '../../shared_components/forms/v2/fields/index'
import Form from '../../shared_components/forms/v2/Form'
import RadioCollection from '../../shared_components/forms/v2/fields/RadioCollection'

import {onBlur, onFocus} from './lib/tools'
import OPTIONS from './data/exampleData.json'
import toggleErrorsAndSideLabelsWrapper from './lib/toggleErrorsAndSideLabelsWrapper'
import validators from '../../shared_components/forms/v2/validators'

// Image Radio List icons:
import GoodEmojiIcon from '../../shared_assets/v2/user_feedback_emojis/emoji-good.png'
import GreatEmojiIcon from '../../shared_assets/v2/user_feedback_emojis/emoji-great.png'
import MehEmojiIcon from '../../shared_assets/v2/user_feedback_emojis/emoji-meh.png'
import TerribleEmojiIcon from '../../shared_assets/v2/user_feedback_emojis/emoji-terrible.png'


export class BasicFormElements extends React.Component {
  constructor() {
    super()

    this.state = {
      keyCode: null,
      processing: false
    }

    this.onChangeDate = this.onChangeDate.bind(this)
    this.onChangePhone = this.onChangePhone.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.submit = this.submit.bind(this)
  }

  onChangeDate(event) {
    const keyCode = this.state.keyCode
    let setDOB = event.target.value

    if (keyCode > 31 && (keyCode < 48 || keyCode > 57) && (keyCode < 36 || keyCode > 40)) { event.target.value = setDOB.slice(0, -1) } else {
      if ((setDOB.length === 2 || setDOB.length === 5) && keyCode !== 8)
        setDOB += '/'

      event.target.value = setDOB
    }
  }

  onChangePhone(event) {
    const keyCode = this.state.keyCode
    let setPhoneNumber = event.target.value
    // const currentPhoneNumber = this.state.phoneNumber

    if (keyCode > 31 && (keyCode < 48 || keyCode > 57) && (keyCode < 36 || keyCode > 40)) { event.target.value = setPhoneNumber.slice(0, -1) } else {
      if ((setPhoneNumber.length === 3 || setPhoneNumber.length === 7) && keyCode !== 8)
        setPhoneNumber += '-'

      event.target.value = setPhoneNumber
    }
  }

  onKeyPress(event) { this.setState({keyCode: event.keyCode}) }

  submit() {
    this.setState({processing: true})
    this.refs.form.onSubmit()
    setTimeout(() => this.setState({processing: false}), 3000) // Pretend we're sending off to the server for errors
  }

  render() {
    return (
      <article>
        <h2>New Forms Elements</h2>

        <article>
          <Form className={classnames('core', {'side-labels': this.props.useSideLabels})} ref='form'>
            <h3>Input Text</h3>
            <Fields.Text
              data={
                fromJS({
                  id: 'input-text-element-1-id',
                  legend: 'Input text',
                  name: 'input-text-1-name',
                  value: 'Default value'
                })
              }
              onBlur={onBlur}
              onFocus={onFocus}
              validator={validators.required}
            />
            <Fields.Text
              data={
                fromJS({
                  id: 'input-text-element-2-id',
                  legend: 'Input text disabled',
                  name: 'input-text-2-name',
                  value: 'Disabled text input'
                })
              }
              disabled
              onBlur={onBlur}
              onFocus={onFocus}
              validator={validators.required}
            />

            <h3>Textarea</h3>
            <Fields.TextArea
              data={
                fromJS({
                  id: 'textarea-element-1-id',
                  legend: 'Notes',
                  name: 'textarea-1-name',
                  placeholder: 'Notes'
                })
              }
              validator={validators.required}
            />
            <Fields.TextArea
              data={
                fromJS({
                  id: 'textarea-element-2-id',
                  legend: 'Notes disabled',
                  name: 'textarea-2-name',
                  placeholder: 'Notes disabled'
                })
              }
              disabled
              validator={validators.required}
            />

            <h2>Radio Controls</h2>

            <h3>Vertical Radio Fields</h3>
            <Fields.VerticalRadioList
              data={
                fromJS({
                  id: 'radio-list-1-id',
                  items: [
                    {label: 'Yes', value: 'Yes'},
                    {label: 'No', value: 'No'},
                    {label: 'Maybe', value: 'Maybe'}
                  ],
                  legend: 'Radio list',
                  name: 'radio-list-1-name'
                })
              }
              validator={validators.requiredImmutableList}
            />
            <Fields.VerticalRadioList
              data={
                fromJS({
                  id: 'radio-list-2-id',
                  items: [
                    {label: 'Yes', value: 'Yes'},
                    {label: 'No', value: 'No'},
                    {label: 'Maybe', value: 'Maybe'}
                  ],
                  legend: 'Radio list disabled',
                  name: 'radio-list-2-name'
                })
              }
              disabled
              validator={validators.requiredImmutableList}
            />

            <h3>Horizontal Radio Fields</h3>

            <h3>Radio Collection</h3>
            <RadioCollection legend='Radio collection legend'>
              <Fields.HorizontalRadioList
                data={
                  fromJS({
                    id: 'radio-collection-1-id',
                    items: [
                      {label: 'Yes', value: 'Yes'},
                      {label: 'No', value: 'No'},
                      {label: 'Maybe', value: 'Maybe'}
                    ],
                    legend: 'Radio Collection 1',
                    name: 'radio-collection-1-name'
                  })
                }
                validator={validators.requiredImmutableList}
              />
              <Fields.HorizontalRadioList
                data={
                  fromJS({
                    id: 'radio-collection-2-id',
                    items: [
                      {label: 'Yes', value: 'Yes'},
                      {label: 'No', value: 'No'},
                      {label: 'Maybe', value: 'Maybe'}
                    ],
                    legend: 'Radio Collection 2',
                    name: 'radio-collection-2-name'
                  })
                }
                validator={validators.requiredImmutableList}
              />
              <Fields.HorizontalRadioList
                data={
                  fromJS({
                    id: 'radio-collection-3-id',
                    items: [
                      {label: 'Yes', value: 'Yes'},
                      {label: 'No', value: 'No'},
                      {label: 'Maybe', value: 'Maybe'}
                    ],
                    legend: 'Radio Collection 3',
                    name: 'radio-collection-3-name'
                  })
                }
                validator={validators.requiredImmutableList}
              />
            </RadioCollection>

            <RadioCollection legend='Radio collection disabled'>
              <Fields.HorizontalRadioList
                data={
                  fromJS({
                    id: 'radio-collection-1-id',
                    items: [
                      {label: 'Yes', value: 'Yes'},
                      {label: 'No', value: 'No'},
                      {label: 'Maybe', value: 'Maybe'}
                    ],
                    legend: 'Radio Collection 1',
                    name: 'radio-collection-1-name'
                  })
                }
                disabled
                validator={validators.requiredImmutableList}
              />
              <Fields.HorizontalRadioList
                data={
                  fromJS({
                    id: 'radio-collection-2-id',
                    items: [
                      {label: 'Yes', value: 'Yes'},
                      {label: 'No', value: 'No'},
                      {label: 'Maybe', value: 'Maybe'}
                    ],
                    legend: 'Radio Collection 3',
                    name: 'radio-collection-2-name'
                  })
                }
                disabled
                validator={validators.requiredImmutableList}
              />
              <Fields.HorizontalRadioList
                data={
                  fromJS({
                    id: 'radio-collection-3-id',
                    items: [
                      {label: 'Yes', value: 'Yes'},
                      {label: 'No', value: 'No'},
                      {label: 'Maybe', value: 'Maybe'}
                    ],
                    legend: 'Radio Collection 3',
                    name: 'radio-collection-3-name'
                  })
                }
                disabled
                validator={validators.requiredImmutableList}
              />
            </RadioCollection>

            <h3>Image Radio List</h3>
            <Fields.ImageRadioList
              data={
                fromJS({
                  id: 'image-radio-list-id',
                  items: [
                    {label: 'Great', value: 'Great', image: GreatEmojiIcon},
                    {label: 'Good', value: 'Good', image: GoodEmojiIcon},
                    {label: 'Not Good', value: 'Not Good', image: MehEmojiIcon},
                    {label: 'Terrible', value: 'Terrible', image: TerribleEmojiIcon}
                  ],
                  legend: 'Image Radio List Example',
                  name: 'image-radio-list-name'
                })
              }
            />

            <h2>Checkbox Controls</h2>
            <Fields.CheckboxList
              data={
                fromJS({
                  id: 'checkbox-list-1-id',
                  items: [
                    {label: 'Homeowners', value: 'Homeowners'},
                    {label: 'Renters', value: 'Renters'},
                    {label: 'Liability/Umbrella', value: 'Liability/Umbrella'},
                    {label: 'Mortgage', value: 'Mortgage'},
                    {label: 'Flood', value: 'Flood'}
                  ],
                  legend: 'Checkbox list',
                  name: 'checkbox-list-1-name'
                })
              }
              validator={validators.requiredImmutableList}
            />
            <Fields.CheckboxList
              data={
                fromJS({
                  id: 'checkbox-list-2-id',
                  items: [
                    {label: 'Homeowners', value: 'Homeowners'},
                    {label: 'Renters', value: 'Renters'},
                    {label: 'Liability/Umbrella', value: 'Liability/Umbrella'},
                    {label: 'Mortgage', value: 'Mortgage'},
                    {label: 'Flood', value: 'Flood'}
                  ],
                  legend: 'Checkbox list disabled',
                  name: 'checkbox-list-2-name'
                })
              }
              disabled
              validator={validators.requiredImmutableList}
            />

            <Buttons.Accept onClick={this.submit} processing={this.state.processing} />


            <h2>Basic Form Elements</h2>
            <fieldset>
              <div className='fieldset-flexbox'>
                <div className='controls-flexbox flex-container'>
                  <label className={classnames('flex-child', {error: this.props.showErrors})}>
                    <span className='label-text'>Label text</span>
                    <input name='input-text-1' type='text' onBlur={onBlur} onFocus={onFocus} />
                    <Error showErrors={this.props.showErrors} />
                  </label>
                  <label className={classnames('flex-child', {error: this.props.showErrors})}>
                    <span className='label-text'>Label text</span>
                    <input disabled name='input-text-3' type='text' onBlur={onBlur} onFocus={onFocus} />
                    <Error showErrors={this.props.showErrors} />
                  </label>
                </div>
              </div>
            </fieldset>

            <h3>Custom Select</h3>
            <Fields.Select
              data={
                fromJS({
                  id: 'new-select-id',
                  items: OPTIONS.NAMES,
                  legend: 'Select name',
                  name: 'What is your name?'
                })
              }
              validator={validators.required}
            />
            <Fields.Select
              data={
                fromJS({
                  id: 'new-select-id-two',
                  items: OPTIONS.LIST,
                  legend: 'Select option',
                  name: 'What option do you want?'
                })
              }
              validator={validators.required}
            />

            <h3>Standard Select</h3>
            <fieldset>
              <div className='fieldset-flexbox controls-group'>
                <legend>Legend text</legend>
                <div className='controls-flexbox flex-container'>
                  <label className={classnames('flex-child select', {error: this.props.showErrors})}>
                    <span className='label-text visually-hidden'>Select option</span>
                    <select name='select-option-1'>
                      <option disabled selected>Select option</option>
                      <option value='1'>Option one</option>
                      <option value='2'>Option two</option>
                      <option value='3'>Option three</option>
                    </select>
                    <Error showErrors={this.props.showErrors} />
                  </label>
                  <label className={classnames('disabled flex-child select', {error: this.props.showErrors})}>
                    <span className='label-text visually-hidden'>Select disabled option</span>
                    <select name='select-option-3' disabled>
                      <option disabled selected>Select disabled option</option>
                      <option value='1'>Option one</option>
                      <option value='2'>Option two</option>
                      <option value='3'>Option three</option>
                    </select>
                    <Error showErrors={this.props.showErrors} />
                  </label>
                </div>
              </div>
            </fieldset>

            <h3>Textarea</h3>
            <fieldset>
              <div className='fieldset-flexbox controls-group'>
                <legend>Legend text</legend>
                <div className='controls-flexbox flex-container'>
                  <label className={classnames('flex-child placeholder-hide-focus', {error: this.props.showErrors})} data-placeholder='Notes for yourself...'>
                    <span className='label-text visually-hidden'>Notes</span>
                    <textarea name='notes-textarea' onBlur={onBlur} onFocus={onFocus} />
                    <Error showErrors={this.props.showErrors} />
                  </label>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div className='fieldset-flexbox controls-group'>
                <legend>Legend text</legend>
                <div className='controls-flexbox flex-container'>
                  <label className={classnames('flex-child placeholder-hide-focus', {error: this.props.showErrors})} data-placeholder='Notes for yourself...'>
                    <span className='label-text visually-hidden'>Notes</span>
                    <textarea disabled name='notes-textarea' onBlur={onBlur} onFocus={onFocus} />
                    <Error showErrors={this.props.showErrors} />
                  </label>
                </div>
              </div>
            </fieldset>

            <h3>Date Input</h3>
            <fieldset>
              <div className='fieldset-flexbox controls-group'>
                <legend>Date of birth</legend>
                <div className='controls-flexbox flex-container date-form-group'>
                  <label className={classnames('flex-child placeholder-hide-focus', {error: this.props.showErrors})} data-placeholder='mm / dd / yyyy'>
                    <span className='label-text visually-hidden'>Date of birth</span>
                    <input
                      maxLength='10'
                      onBlur={onBlur}
                      onChange={this.onChangeDate}
                      onFocus={onFocus}
                      onKeyDown={this.onKeyPress}
                      type='text'
                    />
                    <Error showErrors={this.props.showErrors} />
                  </label>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div className='fieldset-flexbox controls-group'>
                <legend>Date of birth</legend>
                <div className='controls-flexbox flex-container date-form-group'>
                  <label className={classnames('flex-child placeholder-hide-focus', {error: this.props.showErrors})} data-placeholder='mm / dd / yyyy'>
                    <span className='label-text visually-hidden'>Date of birth</span>
                    <input
                      maxLength='10'
                      onBlur={onBlur}
                      onChange={this.onChangeDate}
                      onFocus={onFocus}
                      onKeyDown={this.onKeyPress}
                      type='text'
                      disabled
                    />
                    <Error showErrors={this.props.showErrors} />
                  </label>
                </div>
              </div>
            </fieldset>

            <h3>Phone Input</h3>
            <fieldset>
              <div className='fieldset-flexbox controls-group'>
                <legend>Phone number</legend>
                <div className='controls-flexbox flex-container phone-form-group'>
                  <label className={classnames('flex-child placeholder-hide-focus', {error: this.props.showErrors})} data-placeholder='Enter phone number'>
                    <span className='label-text visually-hidden'>Phone number</span>
                    <input
                      maxLength='12'
                      name='phonenumber'
                      onBlur={onBlur}
                      onChange={this.onChangePhone}
                      onFocus={onFocus}
                      onKeyDown={this.onKeyPress}
                      type='tel'
                    />
                    <Error showErrors={this.props.showErrors} />
                  </label>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div className='fieldset-flexbox controls-group'>
                <legend>Phone number</legend>
                <div className='controls-flexbox flex-container phone-form-group'>
                  <label className={classnames('flex-child placeholder-hide-focus', {error: this.props.showErrors})} data-placeholder='Enter phone number'>
                    <span className='label-text visually-hidden'>Phone number</span>
                    <input
                      maxLength='12'
                      name='phonenumber'
                      onBlur={onBlur}
                      onChange={this.onChangePhone}
                      onFocus={onFocus}
                      onKeyDown={this.onKeyPress}
                      type='tel'
                      disabled
                    />
                    <Error showErrors={this.props.showErrors} />
                  </label>
                </div>
              </div>
            </fieldset>

            <h2>Compound Components</h2>

            <h3>Name Group</h3>
            <Fields.Name
              data={
                fromJS({
                  id: 'first-last-name',
                  name: 'name'
                })
              }
              onBlur={onBlur}
              onFocus={onFocus}
              validator={validators.requiredNameFirstLastValidator}
            />
            <Fields.Name
              data={
                fromJS({
                  id: 'first-last-name-disabled',
                  name: 'name'
                })
              }
              disabled
              onBlur={onBlur}
              onFocus={onFocus}
              validator={validators.requiredNameFirstLastValidator}
            />
            <Fields.Name
              data={
                fromJS({
                  id: 'first-middle-last-name',
                  name: 'name'
                })
              }
              onBlur={onBlur}
              onFocus={onFocus}
              showMiddleInitial={true}
              validator={validators.requiredNameFirstLastValidator}
            />
            <Fields.Name
              data={
                fromJS({
                  id: 'first-middle-last-name-disabled',
                  name: 'name'
                })
              }
              disabled
              onBlur={onBlur}
              onFocus={onFocus}
              showMiddleInitial={true}
              validator={validators.requiredNameFirstLastValidator}
            />

            <h3>Address Group</h3>
            <fieldset>
              <div className='fieldset-flexbox controls-group'>
                <legend>Home Address</legend>
                <div className='address-form-group controls-flexbox flex-container'>
                  <label className={classnames('flex-child placeholder', {error: this.props.showErrors})} data-placeholder='Address line 1'>
                    <span className='label-text visually-hidden'>Address line 1</span>
                    <input name='address-1' type='text' onFocus={onFocus} onBlur={onBlur} />
                  </label>
                  <label className={classnames('flex-child placeholder', {error: this.props.showErrors})} data-placeholder='Address line 2'>
                    <span className='label-text visually-hidden'>Address line 2</span>
                    <input name='address-2' type='text' onFocus={onFocus} onBlur={onBlur} />
                  </label>
                  <label className={classnames('flex-child select', {error: this.props.showErrors})}>
                    <span className='label-text visually-hidden'>Select country</span>
                    <select name='select-country' className='select-option-name'>
                      <option disabled selected defaultValue>Select country</option>
                      <option value='1'>Option One</option>
                      <option value='2'>Option Two</option>
                      <option value='3'>Option Three</option>
                      <option value='4'>Option Four</option>
                      <option value='5'>Option Five</option>
                      <option value='6'>Option Six</option>
                    </select>
                  </label>
                  <label className={classnames('flex-child select', {error: this.props.showErrors})}>
                    <span className='label-text visually-hidden'>Select state</span>
                    <select name='select-state' className='select-option-name'>
                      <option disabled selected defaultValue>Select state</option>
                      <option value='1'>Option One</option>
                      <option value='2'>Option Two</option>
                      <option value='3'>Option Three</option>
                      <option value='4'>Option Four</option>
                      <option value='5'>Option Five</option>
                      <option value='6'>Option Six</option>
                    </select>
                  </label>
                  <label className={classnames('flex-child flex-child-fit placeholder', {error: this.props.showErrors})} data-placeholder='City'>
                    <span className='label-text visually-hidden'>City</span>
                    <input name='city' type='text' onFocus={onFocus} onBlur={onBlur} />
                  </label>
                  <label className={classnames('flex-child flex-child-fit placeholder', {error: this.props.showErrors})} data-placeholder='Zip code'>
                    <span className='label-text visually-hidden'>Zip code</span>
                    <input name='zip-code' type='text' onFocus={onFocus} onBlur={onBlur} />
                  </label>
                  <Error showErrors={this.props.showErrors} />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div className='fieldset-flexbox controls-group'>
                <legend>Home Address</legend>
                <div className='address-form-group controls-flexbox flex-container'>
                  <label className={classnames('flex-child placeholder', {error: this.props.showErrors})} data-placeholder='Address line 1'>
                    <span className='label-text visually-hidden'>Address line 1</span>
                    <input name='address-1' type='text' onFocus={onFocus} onBlur={onBlur} disabled />
                  </label>
                  <label className={classnames('flex-child placeholder', {error: this.props.showErrors})} data-placeholder='Address line 2'>
                    <span className='label-text visually-hidden'>Address line 2</span>
                    <input name='address-2' type='text' onFocus={onFocus} onBlur={onBlur} disabled />
                  </label>
                  <label className={classnames('disabled flex-child select', {error: this.props.showErrors})}>
                    <span className='label-text visually-hidden'>Select country</span>
                    <select name='select-country' className='select-option-name' disabled>
                      <option disabled selected defaultValue>Select country</option>
                      <option value='1'>Option One</option>
                      <option value='2'>Option Two</option>
                      <option value='3'>Option Three</option>
                      <option value='4'>Option Four</option>
                      <option value='5'>Option Five</option>
                      <option value='6'>Option Six</option>
                    </select>
                  </label>
                  <label className={classnames('disabled flex-child select', {error: this.props.showErrors})}>
                    <span className='label-text visually-hidden'>Select state</span>
                    <select name='select-state' className='select-option-name' disabled>
                      <option disabled selected defaultValue>Select state</option>
                      <option value='1'>Option One</option>
                      <option value='2'>Option Two</option>
                      <option value='3'>Option Three</option>
                      <option value='4'>Option Four</option>
                      <option value='5'>Option Five</option>
                      <option value='6'>Option Six</option>
                    </select>
                  </label>
                  <label className={classnames('flex-child flex-child-fit placeholder', {error: this.props.showErrors})} data-placeholder='City'>
                    <span className='label-text visually-hidden'>City</span>
                    <input name='city' type='text' onFocus={onFocus} onBlur={onBlur} disabled />
                  </label>
                  <label className={classnames('flex-child flex-child-fit placeholder', {error: this.props.showErrors})} data-placeholder='Zip code'>
                    <span className='label-text visually-hidden'>Zip code</span>
                    <input name='zip-code' type='text' onFocus={onFocus} onBlur={onBlur} disabled />
                  </label>
                  <Error showErrors={this.props.showErrors} />
                </div>
              </div>
            </fieldset>
          </Form>
        </article>
      </article>
    )
  }
}

BasicFormElements.defaultProps = {
  useSideLabels: false,
  showErrors: false
}

BasicFormElements.propTypes = {
  useSideLabels: PropTypes.bool,
  showErrors: PropTypes.bool
}

export default toggleErrorsAndSideLabelsWrapper(BasicFormElements)
