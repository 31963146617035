import {Component} from 'react'

import PropTypes from 'prop-types'
import {Link} from 'react-router'

import MixpanelLogger from '../../../../lib/MixpanelLogger'
import Input from '../../../../shared_components/Input'
import {Button} from '../../../../dashboard/src/components/blocks/Buttons'
import * as Links from '../../../../shared_components/Links'
import PasswordInput from '../../../../shared_components/PasswordInput'
import SignInActions from '../../actions/SignInActions'
import SessionStore from '../../stores/SessionStore'
import routerUtils from '../../../../lib/routerUtils'
import signInImage from '../../../assets/images/sign-in.svg'

import './signIn.scss'

export default class EmailAndPasswordSignIn extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      signInError: SessionStore.getState().signInError
    }

    this.submitEmailAndPassword = this.submitEmailAndPassword.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.logAndGoToSignUp = this.logAndGoToSignUp.bind(this)
  }

  componentDidMount() {
    SessionStore.addChangeListener(this.onUpdate)
  }

  componentWillUnmount () {
    SessionStore.removeChangeListener(this.onUpdate)
  }

  onUpdate() {
    this.setState({
      signInError: SessionStore.getState().signInError,
      loading: SessionStore.getState().signingIn
    })
  }

  submitEmailAndPassword() {
    this.setState({loading: true})
    let email = this.refs.email.inputValue()
    if (email)
      email = email.toLowerCase().trim()
    const password = this.refs.password.inputValue()

    SignInActions.signIn(email, password, this.context.location.query.nextRoute, this.context.location.query.nextPath)
  }

  logAndGoToSignUp() {
    MixpanelLogger.track('click_signup')
    routerUtils.push('#/auth/sign-up')
  }

  render() {
    return (
      <div className='sign-in'>
        <img alt='' className='hero-image' src={signInImage} />
        <div className='login'>
          <div className='login-title' />
          <h1>Log in to Everplans</h1>
          {
            this.props.flashMessage &&
            <div className='flash-message'>{this.props.flashMessage}</div>
          }
          <form className='clear-all-the-things' autoComplete='on' method='post' noValidate='novalidate'>
            <div>
              <Input
                autoComplete='username'
                autoFocus
                className='sign-in-text-field'
                name='user[email]'
                onChange={SignInActions.clearSignInErrors}
                placeholder='Email address'
                ref='email'
                tabIndex='1'
                type='text'
              />
            </div>
            <div>
              <PasswordInput
                autoComplete='current-password'
                className='sign-in-text-field'
                name='user[password]'
                onFocus={SignInActions.clearSignInErrors}
                placeholder='Password'
                ref='password'
                tabIndex='2'
              />
              <Link to='/auth/password/new' className='forgot-password'>Forgot your password?</Link>
              <div>
                <div className='login-btn'>
                  <Button
                    className='sign-in-btn'
                    processing={this.state.loading}
                    disabled={this.state.loading}
                    onClick={this.submitEmailAndPassword}
                    tabIndex='3'
                    type='submit'>
                      Log in
                  </Button>
                </div>
              </div>
            </div>
            <div className='error'>{this.state.signInError}</div>
            <hr className='login-in-horizontal' />
          </form>
        </div>
        <div className='support-links'>
          {!this.props.location.query.firmcb && <span className='signup-link'>Don't have an account?<a onClick={this.logAndGoToSignUp}>Sign up</a></span>}
        </div>
        <div className='support-links'>
          Need support?<Links.Help>Visit our Help Center</Links.Help>
        </div>
      </div>
    )
  }
}

EmailAndPasswordSignIn.contextTypes = {
  router: PropTypes.object,
  location: PropTypes.object
}

EmailAndPasswordSignIn.defaultProps = {
  location: {
    query: {}
  }
}

EmailAndPasswordSignIn.propTypes = {
  flashMessage: PropTypes.string,
  location: PropTypes.object
}

