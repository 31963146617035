import * as Buttons from '../../../dashboard/src/components/blocks/Buttons'
import CodeBlock from '../CodeBlock'
import CodeExamples from '../CodeExamples'
import CollapsibleSection from './CollapsibleSection'
import {BoxGutterMedium} from '../../../dashboard/src/components/blocks/Boxes'

import {noop} from '../../../lib/tools'


const ButtonsSection = () => (
  <CollapsibleSection heading='Buttons'>
    <BoxGutterMedium>
      <aside>
        <h3>Button: Base</h3>
        <Buttons.Button className='1337-button' onClick={noop}>Button!</Buttons.Button>
        <Buttons.Button className='1337-button' onClick={noop} processing={true}>I'm a really long Button!</Buttons.Button>
        <CodeBlock>{CodeExamples.Buttons.Button}</CodeBlock>
      </aside>
      <aside>
        <h3>Button: Accept</h3>
        <Buttons.Accept className='1337-button' onClick={noop}>Button!</Buttons.Accept>
        <Buttons.Accept className='1337-button' onClick={noop} processing={true}>Button!</Buttons.Accept>
        <Buttons.Accept className='1337-button' onClick={noop} />
        <Buttons.Accept className='1337-button pretend-active' onClick={noop}>Pressed</Buttons.Accept>
        <Buttons.Accept className='1337-button' onClick={noop} disabled>Disabled</Buttons.Accept>
        <CodeBlock>{CodeExamples.Buttons.Accept}</CodeBlock>
      </aside>
      <aside>
        <h3>Button: Add</h3>
        <Buttons.Add className='1337-button' onClick={noop}>Button!</Buttons.Add>
        <Buttons.Add className='1337-button' onClick={noop} />
        <Buttons.Add className='1337-button pretend-active' onClick={noop}>Pressed</Buttons.Add>
        <CodeBlock>{CodeExamples.Buttons.Add}</CodeBlock>
      </aside>
      <aside>
        <h3>Button: AddMore</h3>
        <Buttons.AddMore className='1337-button' onClick={noop} />
        <Buttons.AddMore className='1337-button' onClick={noop} processing={true}>Button!</Buttons.AddMore>
        <Buttons.AddMore className='1337-button' onClick={noop}>Custom Text!</Buttons.AddMore>
        <Buttons.AddMore className='1337-button pretend-active' onClick={noop}>Pressed</Buttons.AddMore>
        <CodeBlock>{CodeExamples.Buttons.AddSolid}</CodeBlock>
      </aside>
      <aside>
        <h3>Button: AddSolid</h3>
        <Buttons.AddSolid className='1337-button' onClick={noop}>Button!</Buttons.AddSolid>
        <Buttons.AddSolid className='1337-button' onClick={noop} processing={true}>Button!</Buttons.AddSolid>
        <Buttons.AddSolid className='1337-button' onClick={noop} />
        <Buttons.AddSolid className='1337-button pretend-active' onClick={noop}>Pressed</Buttons.AddSolid>
        <CodeBlock>{CodeExamples.Buttons.AddSolid}</CodeBlock>
      </aside>
      <aside>
        <h3>Button: Cancel</h3>
        <Buttons.Cancel className='1337-button' onClick={noop}>Button!</Buttons.Cancel>
        <Buttons.Cancel className='1337-button' onClick={noop} processing={true}>Button!</Buttons.Cancel>
        <Buttons.Cancel className='1337-button' onClick={noop} />
        <Buttons.Cancel className='1337-button pretend-active' onClick={noop}>Pressed</Buttons.Cancel>
        <CodeBlock>{CodeExamples.Buttons.Cancel}</CodeBlock>
      </aside>
      <aside>
        <h3>Button: Remove</h3>
        <Buttons.Remove className='1337-button' onClick={noop}>Button!</Buttons.Remove>
        <Buttons.Remove className='1337-button' onClick={noop} processing={true}>Button!</Buttons.Remove>
        <Buttons.Remove className='1337-button' onClick={noop} />
        <Buttons.Remove className='1337-button pretend-active' onClick={noop}>Pressed</Buttons.Remove>
        <CodeBlock>{CodeExamples.Buttons.Remove}</CodeBlock>
      </aside>
    </BoxGutterMedium>
  </CollapsibleSection>
)

export default ButtonsSection
