import CodeBlock from '../../CodeBlock'
import CodeExamples from '../../CodeExamples'
import CollapsibleSection from '../CollapsibleSection'
import {BoxGutterMedium} from '../../../../dashboard/src/components/blocks/Boxes'


const HeadingsSection = () => (
  <CollapsibleSection heading='Headings'>
    <BoxGutterMedium>
      <span dangerouslySetInnerHTML={{__html: CodeExamples.Header}} />
      <CodeBlock>{CodeExamples.Header}</CodeBlock>
    </BoxGutterMedium>
  </CollapsibleSection>
)

export default HeadingsSection
