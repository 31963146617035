export default {
  LIST: [
    {label: 'Select option', value: 'Select option', disabled: true},
    {label: 'Option one', value: 'Option one'},
    {label: 'Option two', value: 'Option two'},
    {label: 'Option three', value: 'Option three'},
    {label: 'Option four', value: 'Option four'},
    {label: 'Option five', value: 'Option five'},
    {label: 'Option six', value: 'Option six'},
    {label: 'Option seven', value: 'Option seven'},
    {label: 'Option eight', value: 'Option eight'},
    {label: 'Option nine', value: 'Option nine'},
    {label: 'Option ten', value: 'Option ten'}
  ],
  NAMES: [
    {label: 'Select name', value: ''},
    {label: 'Howard', value: 'Howard'},
    {label: 'Robin', value: 'Robin'},
    {label: 'Fred', value: 'Fred'},
    {label: 'Gary', value: 'Gary'},
    {label: 'Richard', value: 'Richard'},
    {label: 'Sal', value: 'Sal'}
  ]
}
