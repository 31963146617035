// The footer on this page is hidden when it is everplans branded cobranding style becasue of a check in `Footer.react` --ZD
import {connect} from 'react-redux'
import {Map} from 'immutable'
import PropTypes from 'prop-types'
import {useState, useCallback, useEffect, Fragment} from 'react'
import {readEndpoint} from 'redux-json-api'


import AuthAppClassSideEffect from '../AuthAppClassSideEffect'
import HeaderFlavor from '../../../../shared_components/header/HeaderFlavor'
import PageTitle from '../../../../shared_components/PageTitle'
import TfaSetup from './TfaSetup'
import Loader from '../../../../shared_components/NewLoader'

import SessionStore from '../../../../auth/src/stores/SessionStore'
import TfaConfigStore from '../../stores/TfaConfigStore'
import TfaWizardStore from '../../stores/TfaWizardStore'
import {findRawResourceByAttributeId, findRawResourceById} from '../../../../lib/plan_data/userData'
import {currentUserConfig, userPartner} from '../../../../lib/userTools'
import {needsFirmOrganizationConfig} from '../../../../lib/tools'
import {onTfaExit} from '../../../../lib/interstitialTools'

import './tfaIntro.scss'

const TfaIntro = props => {
  const [currentUser] = useState(SessionStore.getState().currentUser)
  const [tfaConfig, setTfaConfig] = useState(TfaConfigStore.getTfaConfig())
  const [wizardActive, setWizardActive] = useState(TfaWizardStore.active())

  const onUpdate = () => {
    setTfaConfig(TfaConfigStore.getTfaConfig())
    setWizardActive(TfaWizardStore.active())
  }

  const exitTfa = useCallback(event => {
    if (event)
      event.preventDefault()

    onTfaExit(props, currentUser)
  }, [props.location.query.loc, currentUser, props.interstitial, props.partner])

  useEffect(() => {
    props.readEndpoint('user-configs')
      .then(response => {
        // Using the everplan id from the response in situation where user config does not already exist in the store when this request is initiated

        // only clients belonging to a firm with mandatory TFA won't be a premium user at this point and as such this makes sure that needed firm data is fetched
        if (needsFirmOrganizationConfig(response.body.data.attributes) || currentUser.has_interstitials)
          props.readEndpoint(`everplans/${response.body.data.attributes['everplan-id']}?include=firm.organization.organization-config,firm-ownership`)
      })

    props.readEndpoint(`users/${currentUser.id}?include=partner`)

    TfaConfigStore.addChangeListener(onUpdate)
    TfaWizardStore.addChangeListener(onUpdate)

    return () => {
      TfaConfigStore.removeChangeListener(onUpdate)
      TfaWizardStore.removeChangeListener(onUpdate)
    }
  }, [])

  return (
    <Fragment>
      <HeaderFlavor flavor='transactional' />
      <PageTitle title='Two-Step Verification Settings' />
      {!props.interstitial && <AuthAppClassSideEffect className='auth-interstitial' />}
      {
        props.loading ?
          <Loader loading={true} /> :
          <div className='tfa-intro central-content-box'>
            <TfaSetup
              exitTfa={exitTfa}
              onboarding={!!props.location.query.loc || props.interstitial}
              tfaConfig={tfaConfig}
              wizardActive={wizardActive}
              organizationConfig={props.organizationConfig}
            />
          </div>
      }
    </Fragment>
  )
}

TfaIntro.contextTypes = {router: PropTypes.object}

TfaIntro.propTypes = {
  interstitial: PropTypes.bool,
  loading: PropTypes.bool,
  location: PropTypes.shape({query: PropTypes.object}),
  next: PropTypes.func,
  organizationConfig: PropTypes.instanceOf(Map),
  partner: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func
}

const mapStateToProps = state => {
  const userConfig = currentUserConfig(state.api)

  const firmOwnership = findRawResourceByAttributeId({
    rawResource: state.api['firm-ownerships'],
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id')
  })
  const firm = findRawResourceById({
    rawResource: state.api.firms,
    id: firmOwnership.get('ownable-id')
  })

  const organizationConfig = findRawResourceByAttributeId({
    rawResource: state.api['organization-configs'],
    attribute: 'organization-id',
    id: firm.get('organization-id')
  })

  const partner = userPartner({userConfig, partners: state.api.partners})

  return {
    organizationConfig,
    loading: state.api.isReading > 0,
    partner
  }
}

export default connect(mapStateToProps, {readEndpoint})(TfaIntro)
